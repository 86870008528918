
.introduction__scroll-container {
    position: absolute;
    right: 3rem;
    top: 0px;
    bottom: 0px;
    height: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;

    & > h2 {
        color: black;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 15px;
        text-transform: uppercase;
        display: block;
    }

    & > h2 > span {
        display: block;
        margin-bottom: 5px; 
      }

      @include sm {
        right: 2rem;

        & > h2 {
            color: black;
            text-align: center;
            font-size: 8px;
            font-style: normal;
            font-weight: 300;
            line-height: 15px;
            text-transform: uppercase;
            display: block;
        }
    }

    @include xs {
        right: 1rem;
    }
}


.introduction__scroll-arrow {
    position: relative;
    margin-top: 30px;
    padding-left: 10px;

    @include sm {
        padding-left: 12.5px;
    }
}


@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}

.introduction__scroll-arrow span {
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    @include sm {
        width: 0.5rem;
        height: 0.5rem;
    }
}

.introduction__scroll-arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.introduction__scroll-arrow span:nth-child(3) {
    animation-delay: -0.4s;
}
