
.sk-chase-container {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
  }

.sk-chase {
    width: 40px;
    height: 40px;
    position: fixed;
    animation: sk-chase 2.5s infinite linear both;
    z-index: 10;
  }
  
  .sk-chase-dot {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; 
    animation: sk-chase-dot 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:before {
    content: '';
    display: block;
    width: 25%;
    height: 25%;
    background-color: #fff;
    border-radius: 100%;
    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
  }
  
  .sk-chase-dot:nth-child(1) { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2) { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3) { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4) { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5) { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6) { animation-delay: -0.6s; }
  .sk-chase-dot:nth-child(1):before { animation-delay: -1.1s; }
  .sk-chase-dot:nth-child(2):before { animation-delay: -1.0s; }
  .sk-chase-dot:nth-child(3):before { animation-delay: -0.9s; }
  .sk-chase-dot:nth-child(4):before { animation-delay: -0.8s; }
  .sk-chase-dot:nth-child(5):before { animation-delay: -0.7s; }
  .sk-chase-dot:nth-child(6):before { animation-delay: -0.6s; }
  
  @keyframes sk-chase {
    100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
  }
  
  @keyframes sk-chase-dot-before {
    50% {
      transform: scale(0.4); 
    } 100%, 0% {
      transform: scale(1.0); 
    } 
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(100px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }

  @keyframes fadeInLong {
    0% {
      opacity: 0;
      transform: translateY(300px);
    } 100% {
      opacity: 1;
      transform: translateY(0px);
      
    } 
  }
  
  .utils__fade-in-fast,
  .utils__fade-in {
    opacity: 0;
    transition: fadeIn 1s ease-in both;
  }

  .utils__fade-in.appear {
    animation: fadeIn 1s ease-in-out both 0.7s; 
  }

  .utils__fade-in-fast.appear {
    animation: fadeIn 1s ease-in-out both; 
  }

  @keyframes blink {
    0%, 100% {
        background-color: rgba(255, 255, 255, 0);
    }
    50% {
        background-color: rgba(255, 255, 255, 1);
    }
}

@keyframes fillButton {
  0% {
      width: 0%;
  }
  100% {
      width: 100%
  }
}

@keyframes notOnTop {
  0% {
      bottom: -70px;
  }
  100% {
      bottom: 0;
      
  }
}

@keyframes onTop {
  0% {
      bottom: 0;
      top: 0;
  }
  100% {
      bottom: -70px;
  }
}

@keyframes moreSizeImg {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.2);
  }
}

@keyframes lessSizeImg {
  0% {
      transform: scale(1.2);
  }
  100% {
      transform: scale(1);
  }
}

@keyframes levitate {
  0% {
      -webkit-transform: translateY(0);
  }
  50% {
      -webkit-transform: translateY(10px);
  }
  100% {
      -webkit-transform: translateY(0);
  }   
}
